<template>
  <page-section>
    <v-sheet class="wsRoundedHalf pa-6" :style="`border : 3px solid ${wsBACKGROUND}`">
      <h3 :style="`color : ${wsDARKER}; font-size : ${ !SM ? '19px' : '16px' }`"
          :class="[{'font-weight-regular' : SM}]"
          class="text-center"
      >
        {{ $t('homepage.sections.team.title') }}
      </h3>
      <h1 :style="`color : ${wsDARKER}; font-size : ${ !SM ? '34px' : '19px'}; line-height : ${!SM ? '1.4' : '1.5'}`"
          style="max-width: 985px; margin-left: auto;
                 margin-right: auto;"
          class="text-center my-auto mt-6"

      >{{ $t('homepage.sections.team.text') }}</h1>
      <div class="d-flex justify-center">
        <v-btn large  class="noCaps mt-5" elevation="0" :color="wsATTENTION" dark>
          {{$t('homepage.sections.team.details') }}
        </v-btn>
      </div>
      <div class="justify-center align-center pt-16 pb-12"
           :class="[{'d-flex' : !SM}]"
      >
        <ws-tooltip text="RostyslavRyzhkov" style="z-index : 4">
          <v-img :height="!SM ? '362' : null"
                 :width="!SM ? '362' : null"
                 :max-width="!SM ? '362' : null"
                 style="border-radius: 50%; "
                 :alt="$t('RostyslavRyzhkov')"
                 src="@/assets/img/team/ryzhkov_rostyslav.png" />
          <template #text>
            <h5 class="pa-4 text-center" style="font-size: 12px ; line-height: 16px">
              {{ $t('RostyslavRyzhkov') }}<br>
              CEO, {{ $t('CoFounder')}}
            </h5>
          </template>
        </ws-tooltip>
        <ws-tooltip text="VasylRyzhkov"
                    :style="!SM ? `margin-left: -75px; z-index : 3` : null"
        >
          <v-img :height="!SM ? '309' : null"
                 :width="!SM ? '309' : null"
                 :max-width="!SM ? '309' : null"
                 style="border-radius: 50%;"
                 :alt="$t('VasylRyzhkov')"
                 src="@/assets/img/team/ryzhkov_vasyl_3.png" />

          <template #text>
            <h5 class="pa-4 text-center" style="font-size: 12px ; line-height: 16px">
              {{ $t('VasylRyzhkov') }}<br>
              {{ $t('TechnicalDirector') }}, {{ $t('CoFounder')}}
            </h5>
          </template>

        </ws-tooltip>
        <ws-tooltip text="OleksandrRyzhkov"
                    :style="!SM ? `margin-left: -75px; z-index : 2` : null"
        >
          <v-img :height="!SM ? '309' : null"
                 :width="!SM ? '309' : null"
                 :max-width="!SM ? '309' : null"
                 style="border-radius: 50%;"
                 :alt="$t('OleksandrRyzhkov')"
                 src="@/assets/img/team/ryzhkov_oleksandr.png" />
          <template #text>
            <h5 class="pa-4 text-center" style="font-size: 12px ; line-height: 16px">
              {{ $t('OleksandrRyzhkov') }}<br>
              {{ $t('CommercialDirector') }}, {{ $t('CoFounder')}}
            </h5>
          </template>
        </ws-tooltip>
       <ws-tooltip
           text="SerhiyRyzhkov"
           :style="!SM ? `margin-left: -75px; z-index : 1` : null"
       >
         <v-img :height="!SM ? '309' : null"
                :width="!SM ? '309' : null"
                :max-width="!SM ? '309' : null"
                style="border-radius: 50%;"
                :alt="$t('SerhiyRyzhkov')"
                src="@/assets/img/team/ryzhkov_serhiy.png" />
         <template #text>
           <h5 class="pa-4 text-center" style="font-size: 12px ; line-height: 16px">
             {{ $t('SerhiyRyzhkov') }}<br>
             {{ $t('CoFounder') }}
           </h5>
         </template>
       </ws-tooltip>

      </div>

    </v-sheet>
  </page-section>
</template>

<script>
export default {
  name: "homeSectionTeam"
}
</script>

<style scoped>

</style>